import classNames from 'classnames';
import { Component } from 'react';

import styles from './loader.module.css';

type Props = {
  classname?: string;
  isGlobal?: boolean; //used to create overlay while waiting backend response
};

export default class Loader extends Component<Props> {
  render() {
    return (
      <div className={classNames(this.props.isGlobal && styles.loaderWrapper)}>
        <div className={classNames(styles.loader, this.props.classname, this.props.isGlobal && styles.loaderHeight)}></div>
      </div>
    );
  }
}
