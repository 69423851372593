import {
  BetImportedDTO,
  BetSelectionsImportedDTO,
  StrategyDTO,
} from 'src/model/myTypes';

/**
 * ensure we have deafult strategy_id
 */
export const importedBetsInitialAdtaper = (
  stateImportedBets: { [key: string]: BetImportedDTO },
  selectedStrategy: StrategyDTO,
): { [key: string]: BetImportedDTO } => {
  Object.keys(stateImportedBets).forEach((betId) => {
    const bet = stateImportedBets[betId];
    const isValidStrategy = Number(bet.strategy_id) > 0;
    if (!isValidStrategy) {
      bet.strategy_id = Number(selectedStrategy.id);
      stateImportedBets[betId] = { ...bet };
    }
  });
  return stateImportedBets;
};

export const toggleIsSelectedToAproveAdapter = (
  stateImportedBets: { [key: string]: BetImportedDTO },
  betId: string,
  newIsSelected?: boolean,
): { [key: string]: BetImportedDTO } => {
  if (stateImportedBets.hasOwnProperty(betId)) {
    const foundBet = stateImportedBets[betId];
    const isSelected =
      typeof newIsSelected === 'boolean'
        ? newIsSelected
        : !foundBet.isSelectedToSave;
    foundBet.isSelectedToSave = isSelected;
    stateImportedBets[betId] = { ...foundBet };
  }
  return stateImportedBets;
};

export const toggleIsSelectedToAproveBulkAdapter = (
  stateImportedBets: { [key: string]: BetImportedDTO },
  betIdsArray: string[],
  newIsSelected: boolean,
): { [key: string]: BetImportedDTO } => {
  betIdsArray.forEach((betId) => {
    stateImportedBets = toggleIsSelectedToAproveAdapter(
      stateImportedBets,
      betId,
      newIsSelected,
    );
  });
  return stateImportedBets;
};

export const setImportedBetFieldAdapter = (
  stateImportedBets: { [key: string]: BetImportedDTO },
  fieldName: keyof BetImportedDTO,
  value: number,
  betId: string,
  multipleSelectionId?: string,
): { [key: string]: BetImportedDTO } => {
  if (stateImportedBets.hasOwnProperty(betId)) {
    const foundBet = stateImportedBets[betId];
    if (!!multipleSelectionId) {
      // case 1: is a selection of a multiple bet
      const foundSelection =
        foundBet.selections?.find((s) => s.id === multipleSelectionId) ||
        ({} as BetSelectionsImportedDTO);
      foundSelection[fieldName as keyof BetSelectionsImportedDTO] =
        value as never;
    } else {
      // case 2: is a single bet
      foundBet[fieldName] = value as never;
    }

    stateImportedBets[betId] = { ...foundBet };
  }
  return stateImportedBets;
};
