import { ComponentType } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Head, { SeoById, SeoInfoBasic } from './head';

interface Props extends RouteProps {
  component: ComponentType<any>;
  layout: ComponentType<any>;
  seo?: SeoInfoBasic;
  seoById?: SeoById;
}

export const RouteWithLayout = ({
  component: Component,
  layout: Layout,
  seo: seoInfoBasic,
  seoById: seoInfoById,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Head routeSeoData={seoInfoBasic} routeSeoDataById={seoInfoById} />
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};
