import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateTargets } from './initial-state';

import { Targets } from 'src/model/myTypes';
import { TargetsActions } from 'src/redux/actions/action-targets';
import { UserActions } from 'src/redux/actions/action-users';

const unloadedState: Targets = cloneDeep(InitialStateTargets);

export const reducer: Reducer<Targets> = (
  state: Targets | undefined,
  incomingAction: Action,
): Targets => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions | TargetsActions;
  switch (action.type) {
    case 'GET_TARGETS':
      return {
        ...state,
        list: action.payload.targets,
        listTotal: action.payload.pagination.total || 0,
      };
    case 'GET_TARGET_BY_ID':
      return {
        ...state,
        selected_target: {
          id: action.payload.id,
          name: action.payload.name,
          type: action.payload.type,
          interval: action.payload.interval,
          value: action.payload.value,
          initial_date: action.payload.initial_date,
          final_date: action.payload.final_date,
          status: action.payload.status,
        },
      };
    case 'GET_TARGET_VIEW':
      return {
        ...state,
        targetView: {
          target: action.payload.target,
          summary: action.payload.summary,
          periods: action.payload.periods,
        },
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
