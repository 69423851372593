import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateBets } from './initial-state';

import { Bets } from 'src/model/myTypes';
import { BetsActions } from 'src/redux/actions/action-bets';
import { UserActions } from 'src/redux/actions/action-users';
import {
  importedBetsInitialAdtaper,
  setImportedBetFieldAdapter,
  toggleIsSelectedToAproveAdapter,
  toggleIsSelectedToAproveBulkAdapter,
} from 'src/redux/mapper/importedBetMapper';
import { arrayToObject } from 'src/redux/mapper/objectMapper';

const unloadedState: Bets = cloneDeep(InitialStateBets);

export const reducer: Reducer<Bets> = (
  state: Bets | undefined,
  incomingAction: Action,
): Bets => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions | BetsActions;
  switch (action.type) {
    case 'GET_BETS':
      return {
        ...state,
        user_results: action.payload.user_results,
        list: action.payload.list,
        listTotal: action.payload.pagination.total || 0,
      };
    case 'GET_BET_BY_ID':
      return {
        ...state,
        selected_bet: action.payload,
      };
    case 'CLEAR_SELECTED_BET':
      return {
        ...state,
        selected_bet: action.payload,
      };
    case 'GET_UNVALDIATED_BETS':
      const objectImportedBets = arrayToObject(
        'id',
        action.payload.imported_bets,
      );
      return {
        ...state,
        imported_bets: importedBetsInitialAdtaper(
          objectImportedBets,
          action.payload.selectedStrategy,
        ),
      };
    case 'TOGGLE_IMPORTED_BET_IS_SELECTED_TO_APROVAL':
      return {
        ...state,
        imported_bets: toggleIsSelectedToAproveAdapter(
          state.imported_bets,
          action.payload.betId,
        ),
      };
    case 'TOGGLE_IMPORTED_BET_IS_SELECTED_TO_APROVAL_BULK':
      return {
        ...state,
        imported_bets: toggleIsSelectedToAproveBulkAdapter(
          state.imported_bets,
          action.payload.betIdsArray,
          action.payload.newIsSelected,
        ),
      };
    case 'SET_IMPORTED_BET_FIELD':
      return {
        ...state,
        imported_bets: setImportedBetFieldAdapter(
          state.imported_bets,
          action.payload.fieldName,
          action.payload.value,
          action.payload.betId,
          action.payload.multipleSelectionId,
        ),
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
    case 'CREATE_CALENDAR_BET':
      return {
        ...state,
        selected_bet: action.payload,
      };
    case 'EDIT_MULTIPLE_BET':
      return {
        ...state,
        selected_bet: action.payload,
      };
  }
  return state;
};
