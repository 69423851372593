import { Component, createContext } from 'react';
import { connect } from 'react-redux';

import { INITIAL_STATE } from 'src/redux/reducer/initial-state';
import { getCookie } from 'src/utils/cookies';

interface AuthContextInterface {
  isAuthenticated: boolean;
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
});

type Props = { children: JSX.Element } & ReturnType<typeof mapStateToProps>;

class AuthContextProvider extends Component<Props> {
  checkIsAuthenticated(): boolean {
    if (process.env.NODE_ENV !== 'development') {
      return getCookie('PHPSESSID_LOGGED') !== '' ? true : false;
    } else return getCookie('PHPSESSID') !== '' ? true : false;
  }

  render() {
    const isAuthenticated = this.checkIsAuthenticated();
    return (
      <AuthContext.Provider value={{ isAuthenticated }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    user: state.user, // when this changes, component will re-render, then calculate again isAuthenticated flag
  };
};

export default connect(mapStateToProps)(AuthContextProvider);
