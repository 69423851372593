import { cloneDeep } from 'lodash';
import merge from 'lodash/merge';
import throttle from 'lodash/throttle';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import { INITIAL_STATE } from './reducer/initial-state';
import { loadState, saveState } from './storage/local-storage';

import { reducer as betsReducer } from 'src/redux/reducer/reducer-bets';
import { reducer as competitionsReducer } from 'src/redux/reducer/reducer-competitions';
import { reducer as diaryReducer } from 'src/redux/reducer/reducer-diary';
import { reducer as marketsReducer } from 'src/redux/reducer/reducer-markets';
import { reducer as reportsReducer } from 'src/redux/reducer/reducer-reports';
import { reducer as responseReducer } from 'src/redux/reducer/reducer-response';
import { reducer as settingsReducer } from 'src/redux/reducer/reducer-settings';
import { reducer as strategiesReducer } from 'src/redux/reducer/reducer-strategies';
import { reducer as targetsReducer } from 'src/redux/reducer/reducer-targets';
import { reducer as transactionsReducer } from 'src/redux/reducer/reducer-transactions';
import { reducer as userReducer } from 'src/redux/reducer/reducer-users';
import { reducer as jumperReducer } from 'src/redux/reducer/reducer-jumper';

const persistedState = merge(cloneDeep(INITIAL_STATE), { ...loadState() });

const reducers = combineReducers({
  user: userReducer,
  bets: betsReducer,
  diary: diaryReducer,
  settings: settingsReducer,
  markets: marketsReducer,
  competitions: competitionsReducer,
  transactions: transactionsReducer,
  strategies: strategiesReducer,
  reports: reportsReducer,
  targets: targetsReducer,
  response: responseReducer,
  jumper: jumperReducer,
});

export const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(thunk),
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);
