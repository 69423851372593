import { CalendarGames, IGame } from "src/model/myTypes";

/* Convert date to string */
export const getDateString = (dateObject: any) => {
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const todayString =
    year +
    '-' +
    (month <= 9 ? '0' + month.toString() : month.toString()) +
    '-' +
    (day <= 9 ? '0' + day.toString() : day.toString());
  return todayString;
};

/**
 * @returns calendar games by user timezone
 */
export const adpatGamesToUsersTimeZone = (responseData: any) => {
  let gamesByDate: CalendarGames = {};
  const minOffset = new Date().getTimezoneOffset() * (-1);

  responseData.forEach(
    (game: IGame) => {
      const splitedGameDate = game.date_utc.split('-');
      const gameDateYear = Number(splitedGameDate[0]);
      const gameDateMonth = Number(splitedGameDate[1]) - 1;
      const gameDateDay = Number(splitedGameDate[2]);
      const splitedGameTime = game.time_utc.split(':');
      const gameTimeHour = Number(splitedGameTime[0]);
      const gameTimeMinute = Number(splitedGameTime[1]);
      const gameTimeSecond = Number(splitedGameTime[2]);
      const gameInGmtDate = new Date(
        gameDateYear,
        gameDateMonth,
        gameDateDay,
        gameTimeHour,
        gameTimeMinute,
        gameTimeSecond,
      );
      let gameInUsersDate = new Date();
      gameInUsersDate.setTime(gameInGmtDate.getTime() + minOffset * 60 * 1000);
      const gameInUsersDateString: string = getDateString(gameInUsersDate);

      let adpatedGame: IGame = { ...game };
      adpatedGame.date_utc = gameInUsersDateString;
      adpatedGame.time_utc = getTimeString(gameInUsersDate);

      if (!gamesByDate.hasOwnProperty(gameInUsersDateString)) {
        gamesByDate[gameInUsersDateString] = [];
      }

      gamesByDate[gameInUsersDateString].push(adpatedGame);
    },
  );
  return gamesByDate;
};

/**
 * @returns {String} Time in hh:mm:ss format
 */
export const getTimeString = (dateObject: any) => {
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  const timeString =
    (hours <= 9 ? '0' + hours : hours) +
    ':' +
    (minutes <= 9 ? '0' + minutes : minutes) +
    ':' +
    (seconds <= 9 ? '0' + seconds : seconds);

  return timeString;
};

/**
 * @returns today date string in format aaaa-mm-dd
 */
export function getTodayStringUtc(date: Date) {
  const nowDateUtc = new Date(date.toISOString());
  return getDateString(nowDateUtc);
}

/**
 * @returns day timestamp from 00h to 23h59
 */
export function getDayTimestamp(date: string) {
  // Convert the date string to a Date object
  const dateObj = new Date(date);
  // Set the hours, minutes, seconds, and milliseconds to 0, representing the start of the day
  dateObj.setHours(0, 0, 0, 0);
  // Get the Unix timestamp for the start of the day
  const startOfDayTimestamp = Math.floor(dateObj.getTime() / 1000);
  // Get the Unix timestamp for the end of the day
  const endOfDayTimestamp = startOfDayTimestamp + 86400 - 1;

  return { startOfDayTimestamp, endOfDayTimestamp };
}

/**
 * @returns country code for flag
 */
export function getCountryCode(countryId: number) {
  const list = [
    { area_id: 8, name: 'Afeganistão', alpha2code: 'AF' },
    { area_id: 9, name: 'Albânia', alpha2code: 'AL' },
    { area_id: 10, name: 'Algéria', alpha2code: 'DZ' },
    { area_id: 11, name: '', alpha2code: 'AS' },
    { area_id: 12, name: 'Andorra', alpha2code: 'AD' },
    { area_id: 13, name: 'Angola', alpha2code: 'AO' },
    { area_id: 14, name: '', alpha2code: 'AI' },
    { area_id: 15, name: 'Antigua e Barbuda', alpha2code: 'AG' },
    { area_id: 16, name: 'Argentina', alpha2code: 'AR' },
    { area_id: 17, name: 'Arménia', alpha2code: 'AM' },
    { area_id: 18, name: '', alpha2code: 'AW' },
    { area_id: 19, name: 'Áustria', alpha2code: 'AT' },
    { area_id: 20, name: 'Austrália', alpha2code: 'AU' },
    { area_id: 21, name: 'Azerbaijão', alpha2code: 'AZ' },
    { area_id: 22, name: 'Bahamas', alpha2code: 'BS' },
    { area_id: 23, name: 'Barém', alpha2code: 'BH' },
    { area_id: 24, name: 'Bangladexe', alpha2code: 'BD' },
    { area_id: 25, name: 'Barbados', alpha2code: 'BB' },
    { area_id: 26, name: 'Bielorrússia', alpha2code: 'BY' },
    { area_id: 27, name: 'Bélgica', alpha2code: 'BE' },
    { area_id: 28, name: 'Belize', alpha2code: 'BZ' },
    { area_id: 29, name: 'Benim', alpha2code: 'BJ' },
    { area_id: 30, name: '', alpha2code: 'BM' },
    { area_id: 31, name: 'Butão', alpha2code: 'BT' },
    { area_id: 32, name: 'Bolívia', alpha2code: 'BO' },
    { area_id: 33, name: 'Bósnia-Herzegovina', alpha2code: 'BA' },
    { area_id: 34, name: 'Botsuana', alpha2code: 'BW' },
    { area_id: 35, name: 'Brasil', alpha2code: 'BR' },
    { area_id: 36, name: '', alpha2code: 'IO' },
    { area_id: 37, name: 'Brunei', alpha2code: 'BN' },
    { area_id: 38, name: 'Bulgária', alpha2code: 'BG' },
    { area_id: 39, name: 'Burquina Faso', alpha2code: 'BF' },
    { area_id: 40, name: 'Burúndi', alpha2code: 'BI' },
    { area_id: 40, name: 'Camboja', alpha2code: 'KH' },
    { area_id: 42, name: 'Camarões', alpha2code: 'CM' },
    { area_id: 43, name: 'Canadá', alpha2code: 'CA' },
    { area_id: 44, name: 'Cabo Verde', alpha2code: 'CV' },
    { area_id: 45, name: '', alpha2code: 'KY' },
    { area_id: 46, name: 'República Centro-Africana', alpha2code: 'CF' },
    { area_id: 47, name: 'Chade', alpha2code: 'TD' },
    { area_id: 48, name: 'Chile', alpha2code: 'CL' },
    { area_id: 49, name: 'RP China', alpha2code: 'CN' },
    { area_id: 50, name: '', alpha2code: '' },
    { area_id: 51, name: 'Colômbia', alpha2code: 'CO' },
    { area_id: 52, name: 'Congo-Brazzaville', alpha2code: 'CG' },
    { area_id: 53, name: 'República Democrática do Congo', alpha2code: 'CD' },
    { area_id: 54, name: '', alpha2code: 'CK' },
    { area_id: 55, name: 'Costa Rica', alpha2code: 'CR' },
    { area_id: 56, name: 'Croácia', alpha2code: 'HR' },
    { area_id: 57, name: 'Cuba', alpha2code: 'CU' },
    { area_id: 58, name: 'Chipre', alpha2code: 'CY' },
    { area_id: 59, name: 'República Checa', alpha2code: 'CZ' },
    { area_id: 60, name: 'Costa do Marfim', alpha2code: 'CI' },
    { area_id: 61, name: 'Dinamarca', alpha2code: 'DK' },
    { area_id: 62, name: 'Jibuti', alpha2code: 'DJ' },
    { area_id: 63, name: 'Dominica', alpha2code: 'DM' },
    { area_id: 64, name: 'República Dominicana', alpha2code: 'DO' },
    { area_id: 65, name: 'Equador', alpha2code: 'EC' },
    { area_id: 66, name: 'Egipto', alpha2code: 'EG' },
    { area_id: 67, name: 'El Salvador', alpha2code: 'SV' },
    { area_id: 68, name: 'Inglaterra', alpha2code: 'GB-ENG' },
    { area_id: 69, name: 'Guiné Equatorial', alpha2code: 'GQ' },
    { area_id: 70, name: 'Eritreia', alpha2code: 'ER' },
    { area_id: 71, name: 'Estónia', alpha2code: 'EE' },
    { area_id: 72, name: 'Etiópia', alpha2code: 'ET' },
    { area_id: 73, name: '', alpha2code: 'FO' },
    { area_id: 74, name: 'Fiji', alpha2code: 'FJ' },
    { area_id: 75, name: 'Finlândia', alpha2code: 'FI' },
    { area_id: 76, name: 'França', alpha2code: 'FR' },
    { area_id: 77, name: 'Gabão', alpha2code: 'GA' },
    { area_id: 78, name: 'Gâmbia', alpha2code: 'GM' },
    { area_id: 79, name: 'Geórgia', alpha2code: 'GE' },
    { area_id: 80, name: 'Alemanha', alpha2code: 'DE' },
    { area_id: 81, name: 'Gana', alpha2code: 'GH' },
    { area_id: 82, name: 'Grécia', alpha2code: 'GR' },
    { area_id: 83, name: 'Granada', alpha2code: 'GD' },
    { area_id: 84, name: '', alpha2code: 'GU' },
    { area_id: 85, name: 'Guatemála', alpha2code: 'GT' },
    { area_id: 86, name: 'Guiné', alpha2code: 'GN' },
    { area_id: 87, name: 'Guiné-Bissau', alpha2code: 'GW' },
    { area_id: 88, name: 'Guiana', alpha2code: 'GY' },
    { area_id: 89, name: 'Haiti', alpha2code: 'HT' },
    { area_id: 90, name: 'Honduras', alpha2code: 'HN' },
    { area_id: 91, name: 'Hong Kong', alpha2code: 'HK' },
    { area_id: 92, name: 'Húngria', alpha2code: 'HU' },
    { area_id: 93, name: 'Islândia', alpha2code: 'IS' },
    { area_id: 94, name: 'Índia', alpha2code: 'IN' },
    { area_id: 95, name: 'Indonésia', alpha2code: 'ID' },
    { area_id: 96, name: 'Irão', alpha2code: 'IR' },
    { area_id: 97, name: 'Iraque', alpha2code: 'IQ' },
    { area_id: 98, name: 'Irlanda', alpha2code: 'IE' },
    { area_id: 98, name: 'República da Irlanda', alpha2code: 'IE' },
    { area_id: 99, name: 'Israel', alpha2code: 'IL' },
    { area_id: 100, name: 'Itália', alpha2code: 'IT' },
    { area_id: 101, name: 'Jamaica', alpha2code: 'JM' },
    { area_id: 102, name: 'Japão', alpha2code: 'JP' },
    { area_id: 103, name: 'Jordânia', alpha2code: 'JO' },
    { area_id: 104, name: 'Cazaquistão', alpha2code: 'KZ' },
    { area_id: 105, name: 'Quénia', alpha2code: 'KE' },
    { area_id: 106, name: 'Coreia do Norte', alpha2code: 'KP' },
    { area_id: 107, name: 'República da Coreia', alpha2code: 'KR' },
    { area_id: 108, name: 'Cuaite', alpha2code: 'KW' },
    { area_id: 109, name: 'Quirguistão', alpha2code: 'KG' },
    { area_id: 110, name: 'Laus', alpha2code: 'LA' },
    { area_id: 111, name: 'Letónia', alpha2code: 'LV' },
    { area_id: 112, name: 'Líbano', alpha2code: 'LB' },
    { area_id: 113, name: 'Lesoto', alpha2code: 'LS' },
    { area_id: 114, name: 'Libéria', alpha2code: 'LR' },
    { area_id: 115, name: 'Líbia', alpha2code: 'LY' },
    { area_id: 116, name: 'Listenstaine', alpha2code: 'LI' },
    { area_id: 117, name: 'Lituânia', alpha2code: 'LT' },
    { area_id: 118, name: 'Luxemburgo', alpha2code: 'LU' },
    { area_id: 119, name: '', alpha2code: 'MO' },
    { area_id: 120, name: 'Macedónia', alpha2code: 'MK' },
    { area_id: 121, name: 'Madagáscar', alpha2code: 'MG' },
    { area_id: 122, name: 'Maláui', alpha2code: 'MW' },
    { area_id: 123, name: 'Malásia', alpha2code: 'MY' },
    { area_id: 124, name: 'Maldivas', alpha2code: 'MV' },
    { area_id: 125, name: 'Mali', alpha2code: 'ML' },
    { area_id: 126, name: 'Malta', alpha2code: 'MT' },
    { area_id: 127, name: 'Mauritânia', alpha2code: 'MR' },
    { area_id: 128, name: 'Maurícia', alpha2code: 'MU' },
    { area_id: 129, name: 'México', alpha2code: 'MX' },
    { area_id: 130, name: 'Moldávia', alpha2code: 'MD' },
    { area_id: 131, name: 'Mongólia', alpha2code: 'MN' },
    { area_id: 132, name: '', alpha2code: 'MS' },
    { area_id: 133, name: 'Marrocos', alpha2code: 'MA' },
    { area_id: 134, name: 'Moçambique', alpha2code: 'MZ' },
    { area_id: 135, name: 'Mianmar', alpha2code: 'MM' },
    { area_id: 136, name: 'Namíbia', alpha2code: 'NA' },
    { area_id: 137, name: 'Nepal', alpha2code: 'NP' },
    { area_id: 138, name: 'Holanda', alpha2code: 'NL' },
    { area_id: 139, name: '', alpha2code: 'CW' },
    { area_id: 140, name: '', alpha2code: 'NC' },
    { area_id: 141, name: 'Nova Zelândia', alpha2code: 'NZ' },
    { area_id: 142, name: 'Nicarágua', alpha2code: 'NI' },
    { area_id: 143, name: 'Níger', alpha2code: 'NE' },
    { area_id: 144, name: 'Nigéria', alpha2code: 'NG' },
    { area_id: 145, name: 'Irlanda do Norte', alpha2code: 'GB-NIR' },
    { area_id: 146, name: 'Noruega', alpha2code: 'NO' },
    { area_id: 147, name: 'Omã', alpha2code: 'OM' },
    { area_id: 148, name: 'Paquistão', alpha2code: 'PK' },
    { area_id: 149, name: 'Estado da Palestina', alpha2code: 'PS' },
    { area_id: 150, name: 'Panamá', alpha2code: 'PA' },
    { area_id: 151, name: 'Papua Nova Guiné', alpha2code: 'PG' },
    { area_id: 152, name: 'Paraguai', alpha2code: 'PY' },
    { area_id: 153, name: 'Perú', alpha2code: 'PE' },
    { area_id: 154, name: 'Filipinas', alpha2code: 'PH' },
    { area_id: 155, name: 'Polónia', alpha2code: 'PL' },
    { area_id: 156, name: 'Portugal', alpha2code: 'PT' },
    { area_id: 157, name: '', alpha2code: 'PR' },
    { area_id: 158, name: 'Catar', alpha2code: 'QA' },
    { area_id: 159, name: 'Roménia', alpha2code: 'RO' },
    { area_id: 160, name: 'Rússia', alpha2code: 'RU' },
    { area_id: 161, name: 'Ruanda', alpha2code: 'RW' },
    { area_id: 162, name: 'Samoa', alpha2code: 'WS' },
    { area_id: 163, name: 'São Marinho', alpha2code: 'SM' },
    { area_id: 164, name: 'Arábia Saudita', alpha2code: 'SA' },
    { area_id: 165, name: 'Escócia', alpha2code: 'GB-SCT' },
    { area_id: 166, name: 'Senegal', alpha2code: 'SN' },
    { area_id: 167, name: '', alpha2code: '' },
    { area_id: 168, name: 'Seicheles', alpha2code: 'SC' },
    { area_id: 169, name: 'Serra Leoa', alpha2code: 'SL' },
    { area_id: 170, name: 'Singapura', alpha2code: 'SG' },
    { area_id: 171, name: 'Eslováquia', alpha2code: 'SK' },
    { area_id: 172, name: 'Eslovénia', alpha2code: 'SI' },
    { area_id: 173, name: 'Salomão', alpha2code: 'SB' },
    { area_id: 174, name: 'Somália', alpha2code: 'SO' },
    { area_id: 175, name: 'África do Sul', alpha2code: 'ZA' },
    { area_id: 176, name: 'Espanha', alpha2code: 'ES' },
    { area_id: 177, name: 'Sri Lanca', alpha2code: 'LK' },
    { area_id: 178, name: 'São Cristóvão e Neves', alpha2code: 'KN' },
    { area_id: 179, name: 'Santa Lúcia', alpha2code: 'LC' },
    { area_id: 180, name: 'São Vicente e Granadinas', alpha2code: 'VC' },
    { area_id: 181, name: 'Sudão', alpha2code: 'SD' },
    { area_id: 182, name: 'Suriname', alpha2code: 'SR' },
    { area_id: 183, name: 'Suazilândia', alpha2code: 'SZ' },
    { area_id: 184, name: 'Suécia', alpha2code: 'SE' },
    { area_id: 185, name: 'Suíça', alpha2code: 'CH' },
    { area_id: 186, name: 'Síria', alpha2code: 'SY' },
    { area_id: 187, name: 'São Tomé e Príncipe', alpha2code: 'ST' },
    { area_id: 188, name: 'Taiuã', alpha2code: 'TW' },
    { area_id: 189, name: 'Tajiquistão', alpha2code: 'TJ' },
    { area_id: 190, name: 'Tanzânia', alpha2code: 'TZ' },
    { area_id: 191, name: 'Tailândia', alpha2code: 'TH' },
    { area_id: 192, name: 'Togo', alpha2code: 'TG' },
    { area_id: 193, name: 'Tonga', alpha2code: 'TO' },
    { area_id: 194, name: 'Trindade e Tobago', alpha2code: 'TT' },
    { area_id: 195, name: 'Tunísia', alpha2code: 'TN' },
    { area_id: 196, name: 'Turquia', alpha2code: 'TR' },
    { area_id: 197, name: 'Turcomenistão', alpha2code: 'TM' },
    { area_id: 198, name: '', alpha2code: 'TC' },
    { area_id: 199, name: '', alpha2code: 'VI' },
    { area_id: 200, name: 'Uganda', alpha2code: 'UG' },
    { area_id: 201, name: 'Ucrânia', alpha2code: 'UA' },
    { area_id: 202, name: 'Emirados Árabes Unidos', alpha2code: 'AE' },
    { area_id: 203, name: 'Estados Unidos da América', alpha2code: 'US' },
    { area_id: 204, name: 'Uruguai', alpha2code: 'UY' },
    { area_id: 205, name: 'Usbequistão', alpha2code: 'UZ' },
    { area_id: 206, name: 'Vanuatu', alpha2code: 'VU' },
    { area_id: 207, name: 'Venezuela', alpha2code: 'VE' },
    { area_id: 208, name: 'Vietname', alpha2code: 'VN' },
    { area_id: 209, name: 'Gales', alpha2code: 'GB-WLS' },
    { area_id: 210, name: 'Iémen', alpha2code: 'YE' },
    { area_id: 211, name: 'Zâmbia', alpha2code: 'ZM' },
    { area_id: 212, name: 'Zimbábue', alpha2code: 'ZW' },
    { area_id: 220, name: '', alpha2code: '' },
    { area_id: 221, name: 'Timor-Leste', alpha2code: 'TL' },
    { area_id: 223, name: '', alpha2code: 'RE' },
    { area_id: 224, name: 'Sérvia', alpha2code: 'RS' },
    { area_id: 225, name: 'Montenegro', alpha2code: 'ME' },
    { area_id: 226, name: '', alpha2code: 'GI' },
    { area_id: 227, name: '', alpha2code: 'GP' },
    { area_id: 228, name: '', alpha2code: 'MF' },
    { area_id: 229, name: '', alpha2code: 'MQ' },
    { area_id: 230, name: '', alpha2code: 'SX' },
    { area_id: 231, name: '', alpha2code: 'GF' },
    { area_id: 232, name: 'Comores', alpha2code: 'KM' },
    { area_id: 233, name: 'Tuvalu', alpha2code: 'TV' },
    { area_id: 235, name: '', alpha2code: 'JE' },
    { area_id: 236, name: '', alpha2code: 'IM' },
    { area_id: 249, name: 'Cosovo', alpha2code: 'XK' },
    { area_id: 250, name: '', alpha2code: 'GB' },
    { area_id: 251, name: '', alpha2code: 'MP' },
    { area_id: 252, name: 'Quiribáti', alpha2code: 'KI' },
    { area_id: 253, name: 'Mónaco', alpha2code: 'MC' },
    { area_id: 254, name: '', alpha2code: 'GG' },
    { area_id: 255, name: '', alpha2code: 'YT' },
    { area_id: 256, name: 'Sudão do Sul', alpha2code: 'SS' },
    { area_id: 257, name: '', alpha2code: 'NU' },
    { area_id: 258, name: 'Nauru', alpha2code: 'NR' },
    { area_id: 259, name: '', alpha2code: '' },
    { area_id: 260, name: '', alpha2code: 'BQ' },
    { area_id: 261, name: 'Palau', alpha2code: 'PW' },
    { area_id: 262, name: 'Micronésia', alpha2code: 'FM' },
    { area_id: 263, name: 'Vaticano', alpha2code: 'VA' },
    { area_id: 270, name: '', alpha2code: '' },
    { area_id: 271, name: 'Ilhas Marechal', alpha2code: 'MH' },
  ];

  const found = list.find((x) => x.area_id === countryId);

  return found ? found.alpha2code : '';
}
