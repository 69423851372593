import { APIResponseHandler } from 'src/model/myTypes';

type handleErrorAction = {
  type: 'HANDLE_ERROR';
  payload: APIResponseHandler;
};

type handleSuccessAction = {
  type: 'HANDLE_SUCCESS';
  payload: APIResponseHandler;
};

type handleResetRequestSatusAction = {
  type: 'RESET_REQUEST_STATUS';
};

export type ResponseActions =
  | handleErrorAction
  | handleSuccessAction
  | handleResetRequestSatusAction;

/**
 * if payload.type === "custom_message"
 * toast message will print payload.description
 */
export function sendToastError(payload: APIResponseHandler) {
  return {
    type: 'HANDLE_ERROR',
    payload: payload,
  };
}

export function sendToastSuccess(payload: APIResponseHandler) {
  return {
    type: 'HANDLE_SUCCESS',
    payload: payload,
  };
}
export function clearToast() {
  return {
    type: 'RESET_REQUEST_STATUS',
  };
}
