import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateMarkets } from './initial-state';

import { UserMarkets } from 'src/model/myTypes';
import { MarketsActions } from 'src/redux/actions/action-markets';
import { UserActions } from 'src/redux/actions/action-users';

const unloadedState: UserMarkets = cloneDeep(InitialStateMarkets);

export const reducer: Reducer<UserMarkets> = (
  state: UserMarkets | undefined,
  incomingAction: Action,
): UserMarkets => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions | MarketsActions;
  switch (action.type) {
    case 'GET_USER_MARKETS':
      return {
        ...state,
        list: action.payload,
      };
    case 'GET_CUSTOM_MARKET_BY_ID':
      return {
        ...state,
        selected_market: {
          id: action.payload.id,
          sport_id: action.payload.sport_id,
          name: action.payload.name,
          description: action.payload.description,
        },
      };
    case 'DELETE_CUSTOM_MARKET':
      return {
        ...state,
        n_bets: action.payload.n_bets,
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
