import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateStrategies } from './initial-state';

import { Strategies } from 'src/model/myTypes';
import { StrategiesActions } from 'src/redux/actions/action-strategies';
import { UserActions } from 'src/redux/actions/action-users';

const unloadedState: Strategies = cloneDeep(InitialStateStrategies);

export const reducer: Reducer<Strategies> = (
  state: Strategies | undefined,
  incomingAction: Action,
): Strategies => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions | StrategiesActions;
  switch (action.type) {
    case 'GET_STRATEGIES':
      return {
        ...state,
        list: action.payload,
      };
    case 'SET_SELECTED_STRATEGY':
      return {
        ...state,
        selected_strategy: {
          id: action.payload.id,
          name: action.payload.name,
          description: action.payload.description,
        },
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
