import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { INITIAL_STATE } from 'src/redux/reducer/initial-state';

export interface SeoInfoBasic {
  title: string;
  description: string;
}

export interface SeoById {
  [pageSlugId: string]: SeoInfoBasic;
}

export const EMPTY_SEO_RESPONSE = {
  title: '',
  slug: '',
  seo_title: '',
  seo_description: '',
  text: '',
  text2: null,
  text3: null,
};

type OwnProps = {
  routeSeoData?: SeoInfoBasic;
  routeSeoDataById?: SeoById;
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ id: string }>;

class Head extends Component<Props> {
  isValidResponse() {
    return (
      this.props.seoTitle !== EMPTY_SEO_RESPONSE.seo_title ||
      this.props.seoDescription !== EMPTY_SEO_RESPONSE.seo_description
    );
  }

  render() {
    let seoTitle = this.props.seoTitle;
    let seoDescription = this.props.seoDescription;
    if (this.isValidResponse() === false) {
      if (!!this.props.routeSeoData) {
        // case 1: use props.routeSeoData
        seoTitle = this.props.routeSeoData.title;
        seoDescription = this.props.routeSeoData.description;
      } else if (!!this.props.routeSeoDataById) {
        // case 2: use props.routeSeoDataById
        const idSlugName = this.props.match.params.id;
        seoTitle = this.props.routeSeoDataById[idSlugName].title ?? '_';
        seoDescription = this.props.routeSeoDataById[idSlugName].description;
      } else {
        // case 3: leave it empty
        seoTitle = '';
        seoDescription = '';
      }
    }

    const env = process.env.REACT_APP_API_URL;
    const isNoIndex = env?.includes('staging') || env?.includes('codev')
      ? true
      : false;

    return (
      <Helmet>
        {isNoIndex && (
          <meta name="robots" content="noindex" />
        )}
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    seoTitle: state.settings.page.seo_title,
    seoDescription: state.settings.page.seo_description,
  };
};

export default connect(mapStateToProps)(withRouter(Head));
