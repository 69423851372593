import { Component, ComponentType, lazy } from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { SeoById, SeoInfoBasic } from './head';
import { RouteWithLayout } from 'src/components/routeWithLayout';
import { AuthContext } from 'src/contexts/authContext';

import i18n from 'src/i18n';

const Layout = lazy(() => import('./layout'));
const LayoutDashboard = lazy(() => import('src/components/layoutDashboard'));
const HomeSignedIn = lazy(() => import('src/views/home/signedIn/home'));
const HomeSignedOut = lazy(() => import('src/views/home/signedOut/home'));
const SignIn = lazy(() => import('src/views/signIn/signIn'));
const SignUp = lazy(() => import('src/views/signUp/signUp'));
const ConfirmEmail = lazy(() => import('src/views/confirmEmail/confirmEmail'));
const RedefinePassword = lazy(
  () => import('src/views/redefinePassword/redefinePassword'),
);
const Transactions = lazy(() => import('src/views/transactions/transactions'));
const MyBets = lazy(() => import('src/views/myBets/myBets'));
const MyAccount = lazy(() => import('src/views/myAccount/myAccount'));
const Page404 = lazy(() => import('src/views/page404/page404'));
const Reports = lazy(() => import('src/views/reports/reports'));
const BetCalculator = lazy(
  () => import('src/views/betCalculator/betCalculator'),
);
const Targets = lazy(() => import('src/views/targets/targets'));
const ImportForm = lazy(
  () => import('src/views/myBets/importBets/importFrom/importFrom'),
);
const Strategies = lazy(() => import('src/views/strategies/strategies'));
const PublicResultsWrapper = lazy(
  () => import('src/views/publicResults/publicResultsWrapper'),
);
const Calendar = lazy(() => import('src/views/calendar/calendar'));
const TermsAndConditions = lazy(
  () => import('src/views/termsAndPrivacy/termsAndPrivacy'),
);
// const FriendsActivity = lazy(() => import("src/views/friendsActivity/friendsActivity"));
// const Bookmakers = lazy(() => import("src/views/bookmakers/bookmakers"));
// const Diary = lazy(() => import("src/views/diary/diary"));
const InstallProgressiveWebApp = lazy(() => import('src/views/installProgressiveWebApp/installProgressiveWebApp'));

interface RouteData {
  path: string;
  exact?: boolean;
  component: ComponentType<any>;
  layout: ComponentType<any>;
  seo?: SeoInfoBasic;
  seoById?: SeoById;
}

const PUBLIC_ROUTES: RouteData[] = [
  { path: '/', exact: true, component: HomeSignedOut, layout: Layout },
  {
    path: '/sign-up',
    exact: true,
    component: SignUp,
    layout: Layout,
  },
  {
    path: '/confirm-your-email',
    component: ConfirmEmail,
    layout: Layout,
  },
  {
    path: '/redefine-password',
    component: RedefinePassword,
    layout: Layout,
  },
  {
    path: '/public-results/:id',
    exact: true,
    component: PublicResultsWrapper,
    layout: Layout,
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    component: TermsAndConditions,
    layout: Layout,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: TermsAndConditions,
    layout: Layout,
  },
  {
    path: '/install-app',
    exact: true,
    component: InstallProgressiveWebApp,
    layout: Layout,
    seo: {
      title: i18n.t('Download StakeTool PWA'),
      description: i18n.t('Download StakeTool PWA, your betting management companion'),
    },
  },
];

const PRIVATE_ROUTES: RouteData[] = [
  {
    path: '/',
    exact: true,
    component: HomeSignedIn,
    layout: LayoutDashboard,
  },
  {
    path: '/terms-and-conditions',
    exact: true,
    component: TermsAndConditions,
    layout: LayoutDashboard,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: TermsAndConditions,
    layout: LayoutDashboard,
  },
  {
    path: '/install-app',
    exact: true,
    component: InstallProgressiveWebApp,
    layout: LayoutDashboard,
    seo: {
      title: i18n.t('Download StakeTool PWA'),
      description: i18n.t('Download StakeTool PWA, your betting management companion'),
    },
  },
  {
    path: '/calendar',
    exact: true,
    component: Calendar,
    layout: LayoutDashboard,
  },
  {
    path: '/my-bets',
    exact: true,
    component: MyBets,
    layout: LayoutDashboard,
  },
  {
    path: '/my-bets/import-bets/:id',
    exact: true,
    component: ImportForm,
    layout: LayoutDashboard,
  },
  {
    path: '/my-bets/:id',
    exact: true,
    component: MyBets,
    layout: LayoutDashboard,
  },
  {
    path: '/transactions',
    exact: false,
    component: Transactions,
    layout: LayoutDashboard,
  },
  {
    path: '/targets',
    exact: false,
    component: Targets,
    layout: LayoutDashboard,
  },
  {
    path: '/bet-calculator',
    exact: true,
    component: BetCalculator,
    layout: LayoutDashboard,
    seo: {
      title: i18n.t('Bet calculator'),
      description: i18n.t('Preview your winnings, balance required and laybet for a specific bet'),
    },
  },
  {
    path: '/reports',
    exact: false,
    component: Reports,
    layout: LayoutDashboard,
    seoById: {
      'bankroll-evolution': {
        title: i18n.t('Bankroll_evolution'),
        description: i18n.t('View the bankroll report of a strategy for a specific period'),
      },
      'daily-results': {
        title: i18n.t('Daily_results'),
        description: i18n.t('View the daily results of a strategy for a specific period'),
      },
      'accumulated-results': {
        title: i18n.t('Accumulated_results'),
        description: i18n.t('View the accumulated results of a strategy for a specific period'),
      },
      'profit-and-loss': {
        title: i18n.t('Profit_and_loss'),
        description: i18n.t('View the profit and loss of a strategy for a specific period'),
      },
    },
  },
  {
    path: '/my-account',
    exact: true,
    component: MyAccount,
    layout: LayoutDashboard,
    seo: {
      title: i18n.t('My account settings'),
      description: i18n.t(
        'Manage personal data, custom competitions and markets, and check public exported data.',
      ),
    },
  },
  {
    path: '/my-account/:id',
    exact: true,
    component: MyAccount,
    layout: LayoutDashboard,
    seoById: {
      'personal-data': {
        title: i18n.t('Edit Personal Data'),
        description: i18n.t('Manage personal data.'),
      },
      emails: {
        title: i18n.t('Email notifications'),
        description: i18n.t('Manage email notifications preferences.'),
      },
      general: {
        title: i18n.t('General presonal settings'),
        description: i18n.t('Manage default personal settings.'),
      },
      markets: {
        title: i18n.t('Markets'),
        description: i18n.t('View, create, edit and delete custom markets.'),
      },
      competitions: {
        title: i18n.t('Competitions'),
        description: i18n.t(
          'View, create, edit and delete custom competitions.',
        ),
      },
      strategies: {
        title: i18n.t('Strategies'),
        description: i18n.t('View, create, edit and delete custom strategies.'),
      },
      'public-results': {
        title: i18n.t('Public results'),
        description: i18n.t('View all public results exported pages.'),
      },
      'imported-from-extension': {
        title: i18n.t('Imported bets from extension'),
        description: i18n.t(
          'View, edit and aprove all imported bets from chrome extension.',
        ),
      },
    },
  },
  {
    path: '/strategies',
    exact: false,
    component: Strategies,
    layout: LayoutDashboard,
    seo: {
      title: i18n.t('Strategies'),
      description: i18n.t('View, edit, create and delete strategies'),
    },
  },
  {
    path: '/public-results/:id',
    exact: true,
    component: PublicResultsWrapper,
    layout: LayoutDashboard,
    seo: {
      title: i18n.t('Public results'),
      description: i18n.t('View and delete exported public result pages'),
    },
  },
  // {
  //   path: "/diary",
  //   exact: false,
  //   component: Diary,
  //   layout: LayoutDashboard,
  // },
  // {
  //   path: "/bookmakers",
  //   exact: true,
  //   component: Bookmakers,
  //   layout: LayoutDashboard,
  // },
  // {
  //   path: "/friends-activity",
  //   exact: true,
  //   component: FriendsActivity,
  //   layout: LayoutDashboard,
  // },
];

class Routes extends Component {
  render() {
    return (
      <AuthContext.Consumer>
        {(authContext) => {
          const { isAuthenticated } = authContext;

          return (
            <Switch>
              {!isAuthenticated
                ? PUBLIC_ROUTES.map((route, index) => (
                  <RouteWithLayout
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    layout={route.layout}
                    seo={route.seo}
                    seoById={route.seoById}
                  />
                ))
                : PRIVATE_ROUTES.map((route, index) => (
                  <RouteWithLayout
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    layout={route.layout}
                    seo={route.seo}
                    seoById={route.seoById}
                  />
                ))}
              <RouteWithLayout
                exact={true}
                path={'/sign-in'}
                component={SignIn}
                layout={Layout}
              />
              <Route path={'*'} component={Page404} />
            </Switch>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default Routes;
