import { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { clearToast } from 'src/redux/actions/action-response';
import { INITIAL_STATE } from 'src/redux/reducer/initial-state';

const ErrorHandler = lazy(() => import('./errorHandler'));

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = {
  hasToastToReport: boolean
}
class ToastWrapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasToastToReport: false,
    };
  }

  componentDidUpdate() {
    if (JSON.stringify(this.props.status) !== '' && !this.state.hasToastToReport
    ) {
      this.setState({ hasToastToReport: true })
    }
    setTimeout(() => this.props.clearToast(), 2000);
  }

  render() {
    return <ErrorHandler />
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    status: state.response.status,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearToast: () => {
      dispatch(clearToast());
    },
  };
};

export default (connect(mapStateToProps, mapDispatchToProps)(ToastWrapper));