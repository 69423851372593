import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';
import { LastBets, LastPendingBets, Reports } from 'src/model/myTypes';

import { InitialStateReports } from './initial-state';

import { ReportsActions } from 'src/redux/actions/action-reports';
import { UserActions } from 'src/redux/actions/action-users';
import { getLastBetsAdapter } from 'src/redux/mapper/betsMapper';

const unloadedState: Reports = cloneDeep(InitialStateReports);

export const reducer: Reducer<Reports> = (
  state: Reports | undefined,
  incomingAction: Action,
): Reports => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as ReportsActions | UserActions;
  switch (action.type) {
    case 'GET_BANKROLL':
      return {
        ...state,
        bankroll: {
          initial_balance: action.payload.initial_balance,
          minimum_balance: action.payload.minimum_balance,
          maximum_balance: action.payload.maximum_balance,
          final_balance: action.payload.final_balance,
          chart_balances: action.payload.chart_balances,
          all_transactions: action.payload.all_transactions,
        },
      };
    case 'GET_DAILY_RESULTS':
      return {
        ...state,
        results: action.payload.results,
        listTotal: action.payload.pagination.total || 0,
      };
    case 'GET_ACCUMULATED_RESULTS':
      return {
        ...state,
        chart_values: action.payload.chart_values,
        summary: {
          minimum: action.payload.summary.minimum,
          maximum: action.payload.summary.maximum,
          final: action.payload.summary.final,
        },
      };
    case 'GET_PROFIT_AND_LOSS':
      return {
        ...state,
        profit_loss: {
          won_bets: {
            quant: action.payload.won_bets ? action.payload.won_bets.quant : 0,
            percent: action.payload.won_bets
              ? action.payload.won_bets.percent
              : '',
            total_profit: action.payload.won_bets
              ? action.payload.won_bets.total_profit
              : 0,
          },
          lost_bets: {
            quant: action.payload.lost_bets
              ? action.payload.lost_bets.quant
              : 0,
            percent: action.payload.lost_bets
              ? action.payload.lost_bets.percent
              : '',
            total_loss: action.payload.lost_bets
              ? action.payload.lost_bets.total_loss
              : 0,
          },
          days_with_profit: {
            quant: action.payload.days_with_profit
              ? action.payload.days_with_profit.quant
              : 0,
            percent: action.payload.days_with_profit
              ? action.payload.days_with_profit.percent
              : '',
          },
          days_with_loss: {
            quant: action.payload.days_with_loss
              ? action.payload.days_with_loss.quant
              : 0,
            percent: action.payload.days_with_loss
              ? action.payload.days_with_loss.percent
              : '',
          },
          won_graph: !!action.payload.won_graph ? action.payload.won_graph : [],
          lost_graph: !!action.payload.lost_graph
            ? action.payload.lost_graph
            : [],
          bets_graph: !!action.payload.bets_graph
            ? action.payload.bets_graph
            : [],
        },
      };
    case 'GET_DASHBOARD':
      const betsAdapated = getLastBetsAdapter(action.payload.last_bets);
      const pendingBetsAdapated = getLastBetsAdapter(action.payload.last_pending_bets);
      return {
        ...state,
        dashboard: {
          period_result: action.payload.period_result,
          average_profit_per_bet: action.payload.average_profit_per_bet,
          roi: action.payload.roi,
          final_balance: action.payload.final_balance,
          bankroll_evolution: action.payload.bankroll_evolution,
          profit_vs_loss: action.payload.profit_vs_loss,
          main_markets: action.payload.main_markets,
          last_bets: betsAdapated as LastBets[],
          last_pending_bets: pendingBetsAdapated as LastPendingBets[],
        },
      };
    case 'GET_PUBLIC_RESULTS_HASH':
      return {
        ...state,
        publicResultsHash: !!action.payload ? action.payload : [],
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
