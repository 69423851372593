import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateSettings } from './initial-state';

import { Settings } from 'src/model/myTypes';
import { BetsActions } from 'src/redux/actions/action-bets';
import { SettingsActions } from 'src/redux/actions/action-settings';
import { UserActions } from 'src/redux/actions/action-users';

const unloadedState: Settings = cloneDeep(InitialStateSettings);

export const reducer: Reducer<Settings> = (
  state: Settings | undefined,
  incomingAction: Action,
): Settings => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as SettingsActions | UserActions | BetsActions;
  switch (action.type) {
    case 'GET_PAGE':
      return {
        ...state,
        page: {
          seo_title: action.payload.seo_title,
          seo_description: action.payload.seo_description,
          slug: action.payload.slug,
          title: action.payload.title,
          text: action.payload.text,
          text2: {
            main_text: !!action.payload.text2
              ? action.payload.text2.main_text
              : '',
            html_blocks: !!action.payload.text2
              ? action.payload.text2.html_blocks
              : [],
          },
          text3: {
            main_text: !!action.payload.text3
              ? action.payload.text3.main_text
              : '',
            html_blocks: !!action.payload.text3
              ? action.payload.text3.html_blocks
              : [],
          },
        },
      };
    case 'GET_CURRENCIES':
      return {
        ...state,
        currencies: action.payload,
      };
    case 'GET_SPORTS':
      return {
        ...state,
        sports: action.payload,
      };
    case 'GET_COMPETITIONS':
      const competitionsDto = {
        competitions: action.payload.competitions,
        user_last_used_competitions: action.payload.user_last_used_competitions,
        sportId: action.payload.sportId,
      };
      return {
        ...state,
        competitions: competitionsDto,
        competitionsBySport: {
          ...state.competitionsBySport,
          [action.payload.sportId as number]: competitionsDto,
        },
      };
    case 'GET_MARKETS':
      return {
        ...state,
        markets: {
          markets: action.payload.markets,
          user_last_used_markets: action.payload.user_last_used_markets,
          sportId: action.payload.sportId,
        },
      };
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        userLang: action.payload,
      };
    case 'CHANGE_THEME':
      return {
        ...state,
        isDarkMode:
          typeof action.payload !== 'undefined'
            ? action.payload
            : !state.isDarkMode,
      };
    case 'CHANGE_INTERVAL':
      return {
        ...state,
        filters: {
          ...state.filters,
          interval: {
            from: action.payload.from,
            to: action.payload.to,
          },
        },
      };
    case 'CHANGE_PAGINATION':
      return {
        ...state,
        filters: {
          ...state.filters,
          pagination: {
            orderBy: action.payload.orderBy,
            orderDir: action.payload.orderDir,
            offset: action.payload.offset,
            limit: action.payload.limit,
          },
        },
      };
    case 'CHANGE_OPTIONS':
      return {
        ...state,
        filters: {
          ...state.filters,
          options: {
            searchBy: action.payload.searchBy,
            isPending: action.payload.isPending,
          },
        },
      };
    case 'GET_BETS':
      return {
        ...state,
        filters: {
          ...state.filters,
          options: {
            ...action.payload.filter,
            isPending: action.payload.filter.pending === 1 ? true : false,
          },
          pagination: {
            orderBy: action.payload.pagination.orderBy,
            orderDir: action.payload.pagination.orderDir,
            offset: action.payload.pagination.offset,
            limit: action.payload.pagination.limit,
          },
        },
      };
    case 'CLEAR_COMPETITIONS':
      return {
        ...state,
        competitions: {
          competitions: action.payload,
          user_last_used_competitions: action.payload,
          sportId: 0,
        },
        markets: {
          markets: action.payload,
          user_last_used_markets: action.payload,
          sportId: 0,
        },
      };
    case "SET_SIDEBAR_DESKTOP":
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    case "SET_TUTORIAL":
      return {
        ...state,
        tutorial: action.payload,
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
