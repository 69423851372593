import {
  DateInterval,
  OptionCustom,
  UserCompetitionDTO,
  UserMarketDTO,
} from './myTypes';

export const defaultUserCompetition: UserCompetitionDTO = {
  sport_id: 1,
  name: '',
};

export const defaultUserMarket: UserMarketDTO = {
  sport_id: 1,
  name: '',
  description: '',
};

export const defaultInterval: DateInterval = {
  from: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1,
    0,
    0,
    0,
    0,
  ), // get current month initial day
  to: new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0,
    0,
    0,
    0,
    0,
  ), // get current month final day
};

export const defaultOptionCustom: OptionCustom = {
  value: '',
  name: '',
  custom: '',
};
