import {
  defaultInterval,
  defaultUserCompetition,
  defaultUserMarket,
} from 'src/model/defaultMyTypes';
import { DateRange } from 'src/model/enums';
import {
  APIResponseHandler,
  Bets,
  Diary,
  Jumper,
  Reports,
  Settings,
  Strategies,
  Targets,
  Transactions,
  User,
  UserCompetitions,
  UserMarkets,
} from 'src/model/myTypes';
import { safeLanguage } from 'src/utils/language-helper';

export const InitialStateSettings: Settings = {
  isSidebarOpen: true,
  tutorial: {
    isTutorialOpen: false,
    tutorialId: 0,
  },
  isDarkMode: false,
  userLang: safeLanguage(),
  filters: {
    interval: defaultInterval,
    pagination: {
      orderBy: 'date',
      orderDir: 'DESC',
      offset: 0,
      limit: 25,
    },
    options: {
      searchBy: '',
      isPending: false,
    },
  },
  page: {
    seo_title: 'Stake Tool',
    seo_description: '',
    slug: '',
    title: '',
    text: '',
    text2: {
      main_text: '',
      html_blocks: [],
    },
    text3: {
      main_text: '',
      html_blocks: [],
    },
  },
  sports: [],
  competitions: {
    user_last_used_competitions: [],
    competitions: [],
    sportId: 0,
  },
  competitionsBySport: {
    0: {
      user_last_used_competitions: [],
      competitions: [],
      sportId: 0,
    },
  },
  markets: {
    user_last_used_markets: [],
    markets: [],
    sportId: 0,
  },
  currencies: {},
  // navigator.language.indexOf("-") !== -1
  //   ? navigator.language.split("-")[0]
  //   : navigator.language,
};

export const InitialStateUser: User = {
  isValid: false,
  user_name: '',
  email: '',
  initial_balance: '',
  description: '',
  avatar: {
    cropped: null,
    'cropped@2x': null,
    original: null,
    fileName: null,
  },
  settings: {
    default_stake: '',
    default_strategy_id: 0,
    default_sport_id: 0,
    default_currency: '',
    default_date_range: DateRange.This_Month,
  },
  publicResults: {
    hash: '',
    user_name: '',
    bio: '',
    currency: '',
    date_from: '',
    date_to: '',
    period_result: '',
    bets_placed: 0,
    win_bets: 0,
    lost_bets: 0,
    void_bets: 0,
    stake_total: '',
    average_profit_per_bet: '',
    roi: '',
    final_balance: '',
    bankroll_evolution: {
      bankroll_evolution: '',
      chart_balances: [],
      final_balance: '',
      settled_bets: 0,
      starting_balance: '',
    },
    profit_vs_loss: {
      winnings: 0,
      losses: 0,
      biggest_win: '',
      biggest_loss: 0,
    },
    results_by_strategy: [],
    results_by_sport: [],
    results_by_competition: [],
    results_by_market: [],
    bets: [],
  },
  publicResultsBets: {
    pagination: {
      orderBy: 'date',
      orderDir: 'DESC',
      offset: 0,
      limit: 25,
    },
    bets: [],
  },
  exportFilters: {
    interval: defaultInterval,
    sport_id: 0,
    strategy_id: 0,
    competition_id: 0,
    custom_competition_id: 0,
    market_id: 0,
    custom_market_id: 0,
    preview: true,
  },
  bio: '',
  notifications: {
    notifications_enable: false,
    n_betsToAprove: 0,
  },
  registerDevice: {
    fcm_token: '',
    notifications_enable: false,
  }
};

export const InitialStateCompetitions: UserCompetitions = {
  list: [],
  selected_competition: defaultUserCompetition,
  n_bets: '',
};

export const InitialStateMarkets: UserMarkets = {
  list: [],
  selected_market: defaultUserMarket,
  n_bets: '',
};

export const InitialStateBets: Bets = {
  list: [],
  listTotal: 0,
  selected_bet: {
    sport_id: 0,
    strategy_id: 0,
    competition_id: 0,
    custom_competition_id: 0,
    market_id: 0,
    custom_market_id: 0,
    event: '',
    date: '',
    stake: '',
    currency: '',
    result: '',
    odd: '',
    description: '',
    pending_bet: false,
    is_multiple: false,
    title: '',
    selections: [],
    status: "",
  },
  user_results: {
    n_bets: '',
    result: '',
    currency: '',
    roi: '',
  },
  imported_bets: {},
};

export const InitialStateTransactions: Transactions = {
  list: [],
  listTotal: 0,
  selected_transaction: {
    id: 0,
    type: '',
    currency: '',
    value: '',
    date: '',
    description: '',
    strategy_id: 0,
    strategy_name: '',
  },
  summary: {
    n_deposits: 0,
    n_withdrawals: 0,
    deposits: 0,
    withdrawals: 0,
    result: 0,
    currency: 'EUR',
  },
};

export const InitialStateStrategies: Strategies = {
  list: [],
  selected_strategy: {
    id: 0,
    name: '',
    description: '',
    num_bets: '',
    sum_result: '',
  },
};

export const InitialStateReports: Reports = {
  listTotal: 0,
  bankroll: {
    initial_balance: '',
    minimum_balance: '',
    maximum_balance: '',
    final_balance: '',
    chart_balances: {},
    all_transactions: [],
  },
  chart_values: {},
  results: {
    avg_daily_invest: '',
    avg_daily_profit: '',
    roi: '',
    bets_day: '',
    chart_values: {},
    list: [],
  },
  summary: {
    minimum: 0,
    maximum: 0,
    final: 0,
  },
  competitions_overview: {
    chart_data: [],
    list: [],
  },
  markets_overview: {
    chart_data: [],
    list: [],
  },
  losses_analysis: {
    lost_bets: '',
    sum_of_losses: '',
    total_wagers_percent: '',
    average_weight_on_the_stake: '',
    weight_on_the_investiment: '',
    biggests_losses: [],
    biggest_loss: null,
  },
  odds_report: {
    n_bets: 0,
    avg_odd: '',
    result: '',
    roi: '',
    chart_values: {},
  },
  profit_loss: {
    won_bets: { quant: 0, percent: '', total_profit: 0 },
    lost_bets: { quant: 0, percent: '', total_loss: 0 },
    days_with_profit: { quant: 0, percent: '' },
    days_with_loss: { quant: 0, percent: '' },
    won_graph: [],
    lost_graph: [],
    bets_graph: [],
  },
  dashboard: {
    period_result: '',
    average_profit_per_bet: '',
    roi: '',
    final_balance: '',
    bankroll_evolution: {
      chart_balances: [],
      settled_bets: 0,
      starting_balance: '',
      final_balance: '',
      bankroll_evolution: '',
    },
    profit_vs_loss: {
      winnings: 0,
      losses: 0,
      biggest_win: '',
      biggest_loss: 0,
    },
    main_markets: [],
    last_bets: [],
    last_pending_bets: [],
  },
  publicResultsHash: [],
};

export const InitialStateTargets: Targets = {
  list: [],
  listTotal: 0,
  selected_target: {
    id: 0,
    name: '',
    type: '',
    interval: '',
    value: '',
    initial_date: '',
    final_date: '',
    status: '',
  },
  targetView: {
    target: {
      id: 0,
      name: '',
      type: '',
      interval: '',
      value: '',
      initial_date: '',
      final_date: '',
      status: '',
    },
    summary: {
      total_invested: '',
      result: '',
      roi_achieved: '',
      target: '',
      current_value: '',
    },
    periods: [],
  },
};

export const InitialStateDiary: Diary = {
  note: '',
  transactions: [],
};

export const InitialStateResponse: APIResponseHandler = {
  status: '',
  description: '',
  type: '',
};

export const InitialStateJumper: Jumper = {
  calendarGames: {},
};

export const INITIAL_STATE = {
  user: InitialStateUser,
  settings: InitialStateSettings,
  bets: InitialStateBets,
  diary: InitialStateDiary,
  competitions: InitialStateCompetitions,
  markets: InitialStateMarkets,
  transactions: InitialStateTransactions,
  strategies: InitialStateStrategies,
  reports: InitialStateReports,
  targets: InitialStateTargets,
  response: InitialStateResponse,
  jumper: InitialStateJumper,
};
