import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateResponse } from './initial-state';

import { APIResponseHandler } from 'src/model/myTypes';
import { UserActions } from 'src/redux//actions/action-users';
import { BetsActions } from 'src/redux/actions/action-bets';
import { CompetitionActions } from 'src/redux/actions/action-competitions';
import { DiaryActions } from 'src/redux/actions/action-diary';
import { MarketsActions } from 'src/redux/actions/action-markets';
import { ReportsActions } from 'src/redux/actions/action-reports';
import { ResponseActions } from 'src/redux/actions/action-response';
import { SettingsActions } from 'src/redux/actions/action-settings';
import { StrategiesActions } from 'src/redux/actions/action-strategies';
import { TargetsActions } from 'src/redux/actions/action-targets';
import { TransactionsActions } from 'src/redux/actions/action-transactions';

const unloadedState: APIResponseHandler = cloneDeep(InitialStateResponse);

export const reducer: Reducer<APIResponseHandler> = (
  state: APIResponseHandler | undefined,
  incomingAction: Action,
): APIResponseHandler => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as
    | UserActions
    | SettingsActions
    | BetsActions
    | DiaryActions
    | CompetitionActions
    | MarketsActions
    | StrategiesActions
    | TransactionsActions
    | TargetsActions
    | ReportsActions
    | ResponseActions;

  switch (action.type) {
    case 'REGISTER':
    case 'CONFIRM_EMAIL':
    case 'FORGOT_PASSWORD':
    case 'UPDATE_USER_SETTINGS':
    case 'CROP_PHOTO':
    case 'UPDATE_INITIAL_BALANCE':
    case 'RESET_ACCOUNT':
    case 'NEW_BET':
    case 'EDIT_BET':
    case 'NEW_MULTIPLE':
    case 'EDIT_MULTIPLE':
    case 'DELETE_BET':
    case 'NEW_CUSTOM_COMPETITION':
    case 'UPDATE_CUSTOM_COMPETITION':
    case 'CONFIRM_DELETE_CUSTOM_COMPETITION':
    case 'NEW_CUSTOM_MARKET':
    case 'UPDATE_CUSTOM_MARKET':
    case 'CONFIRM_DELETE_CUSTOM_MARKET':
    case 'NEW_STRATEGY':
    case 'UPDATE_STRATEGY':
    case 'DELETE_STRATEGY':
    case 'CONFIRM_DELETE_STRATEGY':
    case 'NEW_TRANSACTION':
    case 'UPDATE_TRANSACTION':
    case 'DELETE_TRANSACTION':
    case 'NEW_TARGET':
    case 'UPDATE_TARGET':
    case 'DELETE_TARGET':
    case 'EDIT_NOTE':
    case 'HANDLE_SUCCESS':
    case 'HANDLE_ERROR':
      return {
        status: action.payload.status,
        description: action.payload.description,
        type: action.payload.type,
      };
    case 'RESET_REQUEST_STATUS':
    case 'LOGOUT':
      // return { ...unloadedState };
      return {
        status: '',
        description: '',
        type: '',
      };
  }
  return state;
};
