export const arrayToObject = (
  keyName: string,
  valuesArray: any[],
): { [key: string]: any } => {
  let result = {};
  valuesArray.forEach((element) => {
    const key = element[keyName].toString();
    result = {
      ...result,
      [key]: element,
    };
  });
  return result;
};
