export enum DateType {
  Day = 0,
  Week = 1,
  Month = 2,
}

export enum Months {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11,
}

export enum Weekdays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum DateRange {
  This_Month = 0,
  Last_30_Days = 1,
  Last_3_Months = 2,
  Last_6_Months = 3,
  Custom_Date = 99,
}

export enum CalendarDateRange {
  Three_Days_Ago = 10,
  Two_Days_Ago = 11,
  One_Day_Ago = 12,
  Today = 13,
  One_Day_From_Now = 14,
  Two_Days_From_Now = 15,
  Three_Days_From_Now = 16,
}

export enum OrderDir {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum TargetInterval {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  EndOfPeriod = 'end_of_period',
}

export enum TargetType {
  BankRollEvolution = 'bankroll_evolution',
  ROI = 'roi',
  Profit = 'profit',
}

export enum TransactionType {
  All = '',
  Withdrawals = 'withdrawals',
  Deposits = 'deposits',
}

export enum BetFieldType {
  Market = 'Market',
  Competition = 'Competition',
  Strategy = 'Strategy',
  Sport = 'Sport',
  Empty = '',
}

export enum ErrorMessage {
  Default = 'Unknown error.',
}

export enum ActivitySettings {
  Share = 'Share',
  Request = 'Request',
  Comments = 'Comments',
}

export enum SelectionType {
  Sport = 'sports',
  Competition = 'competitions',
  Market = 'markets',
  Strategy = 'strategy',
}
