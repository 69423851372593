import { Component, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import ScrollToTop from 'src/components/scrollToTop/scrollToTop';
import Loader from 'src/components/loader/loader';
import Routes from 'src/components/routes';
import AuthContextProvider from 'src/contexts/authContext';
import ToastWrapper from './components/toast/toastWrapper';


class App extends Component {
  render() {
    return (
      <div className="app">
        <AuthContextProvider>
          <Suspense fallback={<Loader classname='appLoader' />}>
            <BrowserRouter>
              {/* <button onClick={() => { throw new Error('Sentry test.') }}>Sentry test</button> */}
              <Routes />
              <ToastWrapper />
              <ScrollToTop />
            </BrowserRouter>
          </Suspense>
        </AuthContextProvider>
      </div>
    );
  }
}

export default Sentry.withProfiler(App);
