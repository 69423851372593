import { BetDTO, LastBets, LastPendingBets, SelectionDTO } from 'src/model/myTypes';

export const getBetsToBetsAdapter = (object: BetDTO[]): Array<BetDTO> => {
  let result: Array<BetDTO> = object.map((bet: any) => ({
    id: bet.id,
    bookmaker_bet_id: bet.bookmaker_bet_id,
    date: bet.date,
    event: bet.event,
    competition_name: bet.competition_name,
    custom_competition_name: bet.custom_competition_name,
    custom_competition_deleted: bet.custom_competition_deleted,
    market_name: bet.market_name,
    custom_market_name: bet.custom_market_name,
    custom_market_deleted: bet.custom_market_deleted,
    strategy_name: bet.strategy_name,
    strategy_id: bet.strategy_id,
    stake: bet.value,
    odd: bet.odd,
    result: bet.result,
    pending_bet: bet.pending_bet,
    sport: bet.sport,
    is_multiple: bet.is_multiple,
    description: bet.description,
    roi: bet.profit,
    multiple_selections: !!bet.multiple_selections
      ? getMultipleBetAdapter(bet.multiple_selections)
      : [],
    currency: bet.currency,
    status: bet.status,
  }));
  return result;
};

const getMultipleBetAdapter = (object: SelectionDTO[]): Array<SelectionDTO> => {
  let result: Array<SelectionDTO> = object.map((bet: any) => ({
    id: bet.id,
    bookmaker_bet_id: bet.bookmaker_bet_id,
    odd: bet.odd,
    date: bet.date,
    event: bet.event,
    competition_name: bet.competition_name,
    custom_competition_name: bet.custom_competition_name,
    custom_competition_deleted: bet.custom_competition_deleted,
    market_name: bet.market_name,
    custom_market_name: bet.custom_market_name,
    custom_market_deleted: bet.custom_market_deleted,
    sport: bet.sport,
    sport_id: bet.sport_id,
    competition_id: bet.competition_id,
    custom_competition_id: bet.custom_competition_id,
    market_id: bet.market_id,
    custom_market_id: bet.custom_market_id,
  }));
  return result;
};

export const getLastBetsAdapter = (object: any): Array<LastBets> | Array<LastPendingBets> => {
  let result: Array<LastBets> = object.map((bet: any) => ({
    id: bet.id,
    event: bet.event,
    stake: bet.value,
    result: bet.result,
    date: bet.date,
    is_multiple: bet.is_multiple,
    roi: bet.roi,
  }));
  return result;
};
