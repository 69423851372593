import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateJumper } from './initial-state';

import { Jumper } from 'src/model/myTypes';
import { JumperActions } from 'src/redux/actions/action-jumper';
import { adpatGamesToUsersTimeZone } from "src/utils/get-games-helpers";
import { UserActions } from 'src/redux/actions/action-users';

const unloadedState: Jumper = cloneDeep(InitialStateJumper);

export const reducer: Reducer<Jumper> = (
  state: Jumper | undefined,
  incomingAction: Action,
): Jumper => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions | JumperActions;
  switch (action.type) {
    case 'GET_CALENDAR_GAMES':
      const calendarGamesAdapter = adpatGamesToUsersTimeZone(action.payload.calendarGames);
      return {
        ...state,
        calendarGames: calendarGamesAdapter,
      };
    case 'CLEAR_CALENDAR_GAMES':
      return {
        ...state,
        calendarGames: action.payload,
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
