import {
  ExportResultsByCompetition,
  ExportResultsByMarket,
  ExportResultsBySports,
  ExportResultsByStrategy,
} from 'src/model/myTypes';

export const getPublicTableAdapter = (data: any) => {
  let tableData: Array<{
    name: string;
    sport?: string;
    n_bets: number;
    profit: number;
    isDeleted?: boolean;
  }> = [];
  const firstItem = data[0] || {};
  if (firstItem.hasOwnProperty('strategy')) {
    // case 1: type ExportResultsByStrategy
    data = data as Array<ExportResultsByStrategy>;
    tableData = data.map((element: ExportResultsByStrategy) => ({
      name: element.strategy,
      n_bets: Number(element.n_bets),
      profit: Number(element.profit),
    }));
  } else if (
    firstItem.hasOwnProperty('sport') &&
    !firstItem.hasOwnProperty('competition') &&
    !firstItem.hasOwnProperty('market')
  ) {
    // case 2: type ExportResultsBySports
    data = data as Array<ExportResultsBySports>;
    tableData = data.map((element: ExportResultsBySports) => ({
      name: element.sport,
      n_bets: Number(element.n_bets),
      profit: Number(element.profit),
    }));
  } else if (firstItem.hasOwnProperty('competition')) {
    // case 3: type ExportResultsByCompetition
    data = data as Array<ExportResultsByCompetition>;
    tableData = data.map((element: ExportResultsByCompetition) => ({
      name: element.competition,
      sport: element.sport,
      n_bets: Number(element.n_bets),
      profit: Number(element.profit),
      isDeleted: !!element.custom_competition_deleted && element.custom_competition_deleted === "1",
    }));
  } else if (firstItem.hasOwnProperty('market')) {
    // case 4: type ExportResultsByMarket
    data = data as Array<ExportResultsByMarket>;
    tableData = data.map((element: ExportResultsByMarket) => ({
      name: element.market,
      sport: element.sport,
      n_bets: Number(element.n_bets),
      profit: Number(element.profit),
      isDeleted: !!element.custom_market_deleted && element.custom_market_deleted === "1",
    }));
  }
  return tableData;
};
