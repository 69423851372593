import { cloneDeep } from 'lodash';

import { INITIAL_STATE } from 'src/redux/reducer/initial-state';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const savedState = JSON.parse(serializedState);
    delete savedState.response; // ensure do not load this part of redux store state
    try {
      // ensure recoreved pagination interval is date object. this prevents multiple getBets call on clientapp\src\views\myBets\myBets.tsx
      (savedState as typeof INITIAL_STATE).settings.filters.interval.from =
        new Date(savedState.settings.filters.interval.from);
      (savedState as typeof INITIAL_STATE).settings.filters.interval.to =
        new Date(savedState.settings.filters.interval.to);
    } catch (error) {
      // do nothing
    }
    return savedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: typeof INITIAL_STATE) => {
  let stateToSave: any = cloneDeep(state);
  delete stateToSave.response; // do not persist toast messages
  const serializedState = JSON.stringify(stateToSave);
  localStorage.setItem('state', serializedState);
};

export const clearLocalStorage = () => {
  localStorage.removeItem('state');
};
