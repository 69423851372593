import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateDiary } from './initial-state';

import { Diary } from 'src/model/myTypes';
import { DiaryActions } from 'src/redux/actions/action-diary';
import { UserActions } from 'src/redux/actions/action-users';

const unloadedState: Diary = cloneDeep(InitialStateDiary);

export const reducer: Reducer<Diary> = (
  state: Diary | undefined,
  incomingAction: Action,
): Diary => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions | DiaryActions;
  switch (action.type) {
    case 'GET_NOTE_AND_TRANSACTIONS':
      return {
        ...state,
        note: action.payload.note,
        transactions: action.payload.transactions,
      };
    case 'GET_NOTE':
      return {
        ...state,
        note: action.payload,
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
