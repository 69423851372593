export function setCookie(cookieName: string, cookieValue: string) {
  let cookieDomain = '';
  let cookieSecure = '';
  if (process.env.NODE_ENV !== 'development') {
    cookieDomain = `domain=${window.location.hostname};`;
    const isSecure = window.location.protocol === 'https:';
    cookieSecure = isSecure ? 'secure;samesite=none;' : '';
  } else {
    cookieDomain = `domain=${window.location.hostname};`;
    cookieSecure = '';
  }
  const cookieParameter = cookieName + '=' + cookieValue + ';';
  document.cookie = cookieParameter + 'path=/;' + cookieDomain + cookieSecure;
}

export function getCookie(cookieName: string) {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function clearCookie() {
  if (process.env.NODE_ENV === 'production') {
    setCookie('PHPSESSID_LOGGED', '');
  }
  setCookie('PHPSESSID', '');
}

// export function deleteCookies() {
//   document.cookie.split(';').forEach((cookies) => {
//     document.cookie = cookies.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
//   });
// }
