import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateCompetitions } from './initial-state';

import { UserCompetitions } from 'src/model/myTypes';
import { CompetitionActions } from 'src/redux/actions/action-competitions';
import { UserActions } from 'src/redux/actions/action-users';

const unloadedState: UserCompetitions = cloneDeep(InitialStateCompetitions);

export const reducer: Reducer<UserCompetitions> = (
  state: UserCompetitions | undefined,
  incomingAction: Action,
): UserCompetitions => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions | CompetitionActions;
  switch (action.type) {
    case 'GET_CUSTOM_COMPETITIONS':
      return {
        ...state,
        list: action.payload,
      };
    case 'GET_CUSTOM_COMPETITION_BY_ID':
      return {
        ...state,
        selected_competition: {
          id: action.payload.id,
          sport_id: action.payload.sport_id,
          name: action.payload.name,
        },
      };
    case 'DELETE_CUSTOM_COMPETITION':
      return {
        ...state,
        n_bets: action.payload.n_bets,
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
