import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateTransactions } from './initial-state';

import { Transactions } from 'src/model/myTypes';
import { TransactionsActions } from 'src/redux/actions/action-transactions';
import { UserActions } from 'src/redux/actions/action-users';

const unloadedState: Transactions = cloneDeep(InitialStateTransactions);

export const reducer: Reducer<Transactions> = (
  state: Transactions | undefined,
  incomingAction: Action,
): Transactions => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions | TransactionsActions;
  switch (action.type) {
    case 'GET_TRANSACTIONS':
      return {
        ...state,
        list: action.payload.list,
        summary: action.payload.summary,
        listTotal: action.payload.pagination.total || 0,
      };
    case 'GET_TRANSACTION_BY_ID':
      return {
        ...state,
        selected_transaction: {
          id: action.payload.id,
          type: action.payload.type,
          currency: action.payload.currency,
          value: action.payload.value,
          date: action.payload.date,
          description: action.payload.description,
          strategy_id: action.payload.strategy_id,
          strategy_name: action.payload.strategy_name,
        },
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
