import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { init, Replay } from '@sentry/react';
import { BrowserTracing } from "@sentry/tracing";

import App from './App';
// import './css/font-sizes.css';
import './css/style-variables.css';
import './i18n';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

//Load service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, (err) => {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}

// The below Sentry configuration captures both error and performance data.
process.env.NODE_ENV === 'production' && init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing(), new Replay()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // To reduce the volume of performance data captured, change tracesSampleRate to a value between 0 and 1.
  tracesSampleRate: 0.2,
  //https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'google is not defined'],
  // SENTRY REPLAY DEVELOPER PLAN LIMITED TO 50 EVENTS/MONTH
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
